/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    left: 26px;
    top: 26px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    min-width: 320px;
  }
  
  .item-container{
    margin-bottom: 32px;
    display:flex;
    gap:15px;
    flex-wrap: nowrap;
    padding: 10px 20px 10px 20px;
    border-width: 1px;
    border-color: #373A47;
  }

  .item-container-selected{
    border-radius: 10px;
    border-width: 1px;
    border-color: #FCFCFC;
  }
  
  @media screen and (min-width: 426px) {
    .bm-menu-wrap {
      min-width: 426px;
    }
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.125em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373A47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #FCFCFC;
    padding: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    text-align: start;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .footer{
    margin-top: auto;
  }